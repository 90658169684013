import React, { useMemo } from 'react';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import './Footer.css';
import styled from 'styled-components';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { NotificationBar, UpperFooter, LowerFooter } from './FooterComponents';
import useNews from '../../models/News';

const MainContainer = styled.div`
  display: ${({ pathname }) => (pathname === '/customers/validate_credit_card' ? 'none' : 'block')};

  @media (max-width: 670px) {
    display: ${({ pathname }) => (pathname === '/car_parks/map' ? 'none' : 'block')};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

function Footer() {
  const history = useHistory();
  const location = useLocation();
  const { announcement } = useNews();

  const showBar = useMemo(() => {
    if (
      history?.location?.pathname.includes('news') ||
      history?.location?.pathname.includes('our-company') ||
      history?.location?.pathname.includes('awards') ||
      history?.location?.pathname === '/en' ||
      history?.location?.pathname === '/zh' ||
      history?.location?.pathname.includes('car_parks')
    ) {
      return !isEmpty(announcement);
    }

    return false;
  }, [announcement, history?.location?.pathname]);

  return (
    <MainContainer id="main-footer" pathname={history?.location?.pathname}>
      <NotificationBar shownotification={showBar} announcement={announcement} />
      <Container id="footer">
        {history?.location?.pathname.includes('/car_parks/detail') ||
        history?.location?.pathname.includes('/car_parks/map') ? null : (
          <UpperFooter />
        )}
        <LowerFooter />
      </Container>
    </MainContainer>
  );
}

export default withRouter(Footer);
