import React from 'react';
import { Link } from 'react-router-dom';
import { InfoWindow } from '@react-google-maps/api';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import * as icon from '../../../media/icons';
import { useParkingService } from '../../../models/CarPark';
import directions from '../../../assets/images/directions.png';
import logo from '../../../media/images/wpLogo.png';

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(33.3% - 4px);

  img {
    object-fit: contain;
    width: 38px;
    height: 38px;
  }
`;

const IconLabel = styled.span`
  display: flex;
  width: 100%;
  color: #888888;
  font-size: 0.75rem;
  font-weight: normal;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  height: 100%;
  margin-top: 5px;
`;

const ServiceIcon = ({ serviceIcon, name }) => {
  return (
    <IconContainer>
      <div style={{ minHeight: 40, height: 40 }}>
        <img alt={`${name}_icon`} src={`${process.env.REACT_APP_CP_MEDIA_DOMAIN}${serviceIcon}`} />
      </div>

      <IconLabel dangerouslySetInnerHTML={{ __html: name }} />
    </IconContainer>
  );
};

const ClickableInfo = styled(Link)`
  text-decoration: none !important;
  padding-right: 45px;
  position: relative;
  display: block;

  &:after {
    position: absolute;
    top: 50%;
    right: -12px;
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 1px solid transparent;
    line-height: 24px;
    color: #ffffff;
    pointer-events: none;
    content: '';
    background-image: url(${icon.nextwhite});
    background-repeat: no-repeat;
    background-position: 55% center;
    background-size: 60% auto;
    border-radius: 50%;
    vertical-align: middle;
    background-color: #fd980f;
    transform: translate(-50%, -50%);
  }
`;

const Name = styled.span`
  display: inline-block;
  padding: 0 0 0px;
  width: 100%;
  color: #fd980f;
  font-size: 1.125rem;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 6px;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
`;

const Address = styled.span`
  display: inline-block;
  color: #444444;
  width: 100%;
  font-size: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
`;

const Phone = styled.span`
  padding: 11px 0;
  color: #222222;
  font-size: 0.875rem;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
`;

const DirectionsButton = styled.button`
  border: 2px #fd980f solid;
  display: block;
  text-align: center;
  font-size: 18px;
  border-radius: 6px;
  padding: 5px;
  text-decoration: none;
  margin-top: 12px;
  color: #fd980f;
  position: relative;
  line-height: inherit;
  vertical-align: baseline;
  background: transparent;
  width: 100%;

  img {
    height: 25px;
    width: 25px;
    position: absolute;
    top: 3px;
    left: 8px;
    display: block;
  }
`;

export default function DesktopInfoDisplay({ marker, onMarkerClick }) {
  const { t, i18n } = useTranslation();
  const { services } = useParkingService();
  const { id, image, name, address, phone = 'N/A', features, coordinates } = marker;
  const { latitude, longitude } = coordinates || {};

  const displayFeatures =
    services && features && features.length > 0
      ? services.filter(
          ({ id: sid, icon: serviceIcon, isShowIcon, isEnable }) =>
            features.includes(sid) && serviceIcon && isShowIcon && isEnable
        )
      : [];

  return (
    <InfoWindow
      onCloseClick={() => onMarkerClick(undefined)}
      position={{
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      }}
      options={{
        disableAutoPan: true,
        pixelOffset: new window.google.maps.Size(180, 170),
        maxWidth: 300,
      }}
    >
      <div>
        <div style={{ overflow: 'hidden', borderTopLeftRadius: 8, padding: !image && '40px 0px' }}>
          <img
            src={image ? `${process.env.REACT_APP_CP_MEDIA_DOMAIN}${image}` : logo}
            alt=""
            width="100%"
            height="170px"
          />
        </div>
        <div style={{ padding: 15 }}>
          <ClickableInfo
            to={`/${i18n.language}/car_parks/detail/${id}`}
            role="button"
            // onClick={cookies.set('carParkId', id)}
          >
            <Name>{name}</Name>
            <Address>{address}</Address>

            {/* <ArrowButton /> */}
          </ClickableInfo>

          <Phone>
            {`${t('Map.Phone')}`}
            <span style={{ marginLeft: 20 }}>{phone}</span>
          </Phone>
          {displayFeatures.length > 0 && (
            <>
              <hr style={{ marginTop: 0 }} />
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px 5px' }}>
                {displayFeatures.map(({ type, icon: serviceIcon }) => (
                  <ServiceIcon key={type} serviceIcon={serviceIcon} name={type} />
                ))}
              </div>
            </>
          )}
          <DirectionsButton
            onClick={() =>
              window.open(
                `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}&travelmode=driving`
              )
            }
          >
            <img alt="" src={directions} />
            {t('Map.Directions')}
          </DirectionsButton>
        </div>
      </div>
    </InfoWindow>
  );
}
