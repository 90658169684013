import React from 'react';
import styled from 'styled-components';

const Icon = styled.img`
  width: 320px;
  max-width: 45%;
  /* height: 200px; */
  position: fixed;
  bottom: 20px;
  right: 20px;
  object-fit: contain;
  z-index: 101;
  cursor: pointer;
`;

export default function FloatingIcon({ data }) {
  const imageUrl = `${process.env.REACT_APP_CP_MEDIA_DOMAIN.replace('static', '')}${
    data?.floating_icon_url
  }`;

  if (!data?.floating_icon_url) {
    return null;
  }

  return (
    <Icon
      onClick={() => {
        window.open(data?.destination_url, '_blank');
      }}
      src={imageUrl}
    />
  );
}
