/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react';
import { useParams, withRouter, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import AliceCarousel from 'react-alice-carousel';
import UpperItemRow from './UpperItemRow';
import BottomTextRow from './BottomTextRow';
import useNews from '../../models/News';
import next from '../../media/images/slider_next_white.png';
import prev from '../../media/images/slider_prev_white.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  Container,
  MainContainer,
  PageTitle,
  UpperContainer,
  ArrowDiv,
  LeftArrow,
  RightArrow,
  BottomContanier,
} from './style';

const News = () => {
  const { hash } = useLocation();
  const newsIndex = hash ? parseInt(hash.replace('#', ''), 10) : 0;
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);
  const { PromoData, news = [] } = useNews();
  const UpperItemRef = useRef();
  const [focusOnSelect, setFocusOnSelect] = useState(false);

  function Next(props) {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        style={{ ...style, width: 20, height: 40 }}
        src={next}
        onClick={onClick}
        alt=""
        aria-hidden="true"
      />
    );
  }

  function Prev(props) {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        style={{ ...style, width: 20, height: 40 }}
        src={prev}
        onClick={onClick}
        alt=""
        aria-hidden="true"
      />
    );
  }

  const settings = {
    centerMode: true,
    infinite: true,
    slidesToShow: news.length > 3 ? 3 : news.length,
    slidesToScroll: 1,
    speed: 500,
    centerPadding: '0px',
    beforeChange: (current, newIndex) => {
      setIndex(newIndex);
    },
    focusOnSelect,
    nextArrow: <Next />,
    prevArrow: <Prev />,
    responsive: [
      {
        breakpoint: 850,
        settings: {
          centerMode: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 500,
          centerPadding: '0px',
          beforeChange: (current, newIndex) => setIndex(newIndex),
          focusOnSelect,
        },
      },
    ],
  };

  // useEffect(() => {
  //   if (newsIndex && UpperItemRef.current) {
  //     setTimeout(() => {
  //       UpperItemRef.current.slickGoTo(newsIndex);
  //     }, 1000);
  //   }
  // }, [newsIndex, UpperItemRef]);

  return (
    <Container>
      <MainContainer>
        <PageTitle>{t('News.NEWS')}</PageTitle>
        <UpperContainer
          onMouseOver={() => setFocusOnSelect(true)}
          onMouseLeave={() => setFocusOnSelect(false)}
        >
          <Slider {...settings} ref={UpperItemRef}>
            {news.map((record, innerIndex) => (
              <UpperItemRow
                key={record.id}
                detail={record}
                index={index}
                t={t}
                innerIndex={innerIndex}
              />
            ))}
          </Slider>
        </UpperContainer>

        <ArrowDiv>
          <LeftArrow />
          <RightArrow />
        </ArrowDiv>
      </MainContainer>
      <BottomContanier>
        <AliceCarousel
          animationType="fadeout"
          activeIndex={index}
          autoHeight
          infinite
          disableButtonsControls
          disableDotsControls
          items={news.map((records) => (
            <BottomTextRow detail={records} key={records.id} />
          ))}
        />
      </BottomContanier>
    </Container>
  );
};

export default withRouter(News);
