/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { useCarParks } from '../../models/CarPark';
import MapInner from './Components/MapInner';
import Map from '../../components/General/Map';

const MainContainer = styled.div`
  position: relative;
  flex: 1;

  .gm-style .gm-style-iw-c {
    @media (max-width: 770px) {
      display: none;
    }
  }

  .gm-style .gm-style-iw-tc::after {
    display: none;
  }

  .gm-ui-hover-effect {
    span {
      display: none !important;
    }
  }
`;

const containerStyle = {
  height: '100%',
};

function AllCarParks() {
  const carParksHook = useCarParks();
  const { setCarParksDisplay, carParksDisplay } = carParksHook;

  const [zoom, setZoom] = useState(13);
  const [center, setCenter] = useState({ lat: 22.302219, lng: 114.174637 });

  return (
    <MainContainer>
      <Map
        containerStyle={containerStyle}
        center={center}
        zoom={zoom}
        markers={carParksDisplay}
        options={{ scrollwheel: true }}
      >
        <MapInner
          carParksHook={carParksHook}
          setCarParksDisplay={setCarParksDisplay}
          markers={carParksDisplay}
          setZoom={setZoom}
          setCenter={setCenter}
          center={center}
          zoom={zoom}
        />
      </Map>
    </MainContainer>
  );
}

export default withRouter(AllCarParks);
