import styled from 'styled-components';
import moment from 'moment';
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { capitalize } from '../../../helpers/generalHelper';
import * as icon from '../../../media/icons';
import {
  ItemContainer,
  ItemNumberLabel,
  ItemNumber,
  CarParkName,
  CarParkAddress,
  Label,
  Detail,
  DollarSign,
  Fee,
  ChangeCarButton,
  StatusDetail,
  DetailInnerDiv,
} from '../../Cart/CartItem';
import StyledLink from '../../../components/General/StyledLink';

const BookingItemView = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 15px;
  padding-top: 10px;

  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

const ItemBlock = styled.div`
  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

const ItemTabel = styled.table`
  width: 50%;
  margin-right: 20px;
  @media (max-width: 850px) {
    width: 100%;
    margin-right: 0px;
  }
`;

const DetailContainer = styled.div`
  flex-basis: 70%;
  border-right: 1px solid #dedede;

  @media (max-width: 850px) {
    border-right: none;
  }
`;

const DetailInner = styled.div`
  flex-basis: 15%;
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  padding-top: 20px;

  @media (max-width: 850px) {
    margin-right: 0px;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #dedede;
    margin-top: 20px;
  }

  & > button {
    width: 150px;
    max-width: 150px;
    max-height: 32px;

    @media (max-width: 850px) {
      width: 90%;
      max-width: 90%;
    }
  }
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 850px) {
    flex-direction: row;
    width: 90%;
    justify-content: space-between;
  }

  button {
    margin-top: 10px;
    width: 150px;
    max-width: 150px;
    min-height: 32px;

    @media (max-width: 850px) {
      &:not(:first-child) {
        margin-left: 20px;
      }
      width: 100%;
      max-width: 100%;
      margin: 10px 0px 0px 0px;
    }
  }
`;

export default function BookingItem({
  index,
  booking,
  changeCar,
  handleTerminate,
  changeService,
  book,
  pageNumber,
  handleTempChangeTerminate,
}) {
  const { status, ocrStatus, validFrom, validTo } = booking;
  const { t, i18n } = useTranslation();

  const [renewLoading, setRenewLoading] = useState(false);

  const canChangeCar = useMemo(() => {
    return (
      (status === 'active' || status === 'paid') &&
      changeCar &&
      moment().isBetween(moment(validFrom), moment(validTo), 'date', '[]')
    );
  }, [status, changeCar]);

  const renewAvailable = useMemo(() => {
    const today = moment().format('DD');

    if (
      !moment(booking?.validTo).isValid() ||
      moment(booking?.validTo).utcOffset(0).month() !== moment().utcOffset(8).month() ||
      !(booking?.validTo || '').includes(moment().format('YYYY'))
    ) {
      return false;
    }
    if (status === 'active' || status === 'paid') {
      return today >= (process.env.REACT_APP_RENEWAL_DATE || '22');
    }

    return false;
  }, []);

  const showTempChangeTerminateButton = false;
  // TODO: Revert back later to const showTempChangeTerminateButton = status === 'temporary' && booking.validTo && moment().isBefore(moment(booking.validTo), 'date');

  function handelStatusIcon() {
    if (
      (status === 'pending' && ocrStatus === 'matched') ||
      ocrStatus === 'approved' ||
      status === 'temporary'
    ) {
      return icon.check;
    }
    if (status === 'pending' || status === 'overpaid') {
      return icon.warning;
    }
    if (
      status === 'paid' ||
      status === 'renewed' ||
      status === 'settled' ||
      status === 'changed' ||
      status === 'valid' ||
      status === 'active'
    ) {
      return icon.check;
    }
    if (status === 'expired' || status === 'terminated') {
      return icon.cross;
    }
    return icon.warning;
  }

  function handelStatus() {
    if ((status === 'pending' && ocrStatus === 'matched') || ocrStatus === 'approved') {
      return 'paid';
    }
    switch (status) {
      case 'valid':
      case 'active':
        return 'active';
      case 'renewed':
      case 'renewed-changed':
        return 'renewed';
      default:
        return status;
    }
  }

  function handelPrice() {
    if (booking?.type === 'change license') {
      if (status === 'temporary' || status === 'tempChangeTerminated') {
        return 0;
      }
      return 50;
    }
    if (booking?.type === 'new') {
      // case same month
      if (moment(booking?.validFrom).isSame(moment(booking?.validTo), 'month')) {
        const currentYearMonthDay16 = moment(
          `${moment(booking?.validFrom).format('YYYY MM')} 16`,
          'YYYY MM DD'
        );
        // case same month same day but after 16
        if (moment(booking?.validFrom).isSameOrAfter(currentYearMonthDay16, 'day')) {
          return booking.amount * 0.5;
        }
        return booking.amount;
      }

      return booking.amount * 1.5;
    }

    return booking.amount;
  }

  return (
    <ItemContainer background={index % 2 === 0 ? '#fff' : '#fef1e5'}>
      <ItemNumberLabel>
        <ItemNumber>{index + 1 + (pageNumber * 20 - 20)}</ItemNumber>
      </ItemNumberLabel>

      <BookingItemView>
        <DetailContainer>
          <CarParkName>{booking.name}</CarParkName>
          <div style={{ margin: '4px 0 20px 0', display: 'flex' }}>
            <img
              src="https://www.wilsonparking.com.hk/img/icon-pin-info.png"
              width="14"
              height="17"
              alt="location"
            />
            <CarParkAddress>{booking.address}</CarParkAddress>
          </div>

          <ItemBlock className="d-flex-polyfill">
            <ItemTabel cellPadding="5">
              <tbody>
                <tr>
                  <Label width="35%">{t('MyAccount.iMonthly.ServiceStatus')}</Label>
                  <Detail>
                    <StatusDetail>
                      <DetailInnerDiv>
                        <img
                          style={{ marginRight: 10, width: 15, height: 15, border: 'none' }}
                          src={handelStatusIcon()}
                          alt=""
                        />
                        {status
                          ? capitalize(t(`MyAccount.iMonthly.status.${handelStatus()}`))
                          : '-'}
                      </DetailInnerDiv>
                    </StatusDetail>
                  </Detail>
                </tr>
                <tr>
                  <Label width="35%">{t('MyAccount.iMonthly.Service')}</Label>
                  <Detail width="65%">{booking.service}</Detail>
                </tr>
                <tr>
                  <Label width="35%">{t('MyAccount.iMonthly.LastPaymentDate')}</Label>
                  <Detail width="65%">{booking.lastPaymentDate}</Detail>
                </tr>
              </tbody>
            </ItemTabel>

            <ItemTabel cellPadding="5">
              <tbody>
                <tr>
                  <Label width="35%">{t('MyAccount.iMonthly.ServiceId')}</Label>
                  <Detail width="65%">{`#${booking.id}`}</Detail>
                </tr>
                <tr>
                  <Label width="35%">{t('MyAccount.iMonthly.ServicePeriod')}</Label>
                  <Detail width="65%">{booking.period}</Detail>
                </tr>
                <tr>
                  <Label width="35%">{t('MyAccount.iMonthly.AssignedCar')}</Label>
                  <Detail width="65%">
                    {booking.registrationMark}
                    {canChangeCar && (
                      <ChangeCarButton onClick={changeCar}>
                        {`(${t('MyAccount.iMonthly.ChangeCar')})`}
                      </ChangeCarButton>
                    )}
                  </Detail>
                </tr>
              </tbody>
            </ItemTabel>
          </ItemBlock>
        </DetailContainer>

        <DetailInner>
          <div
            className="d-flex-polyfill"
            style={{ display: 'flex', marginBottom: 15, justifyContent: 'center' }}
          >
            <DollarSign>HK$</DollarSign>
            <Fee>{handelPrice()}</Fee>
          </div>

          {renewAvailable && book && (
            <StyledLink
              isLoading={renewLoading}
              disabled={renewLoading}
              color="yellow"
              onClick={async () => {
                await book();
                setRenewLoading(true);
              }}
              label={t('MyAccount.iMonthly.RENEW')}
            />
          )}
          <ButtonRow>
            {(status === 'active' || status === 'paid' || showTempChangeTerminateButton) &&
              handleTempChangeTerminate && (
                <StyledLink
                  type="button"
                  color="grey"
                  onClick={() => {
                    Swal.fire({
                      position: 'top',
                      text: t('MyAccount.iMonthly.confirmTerminate'),
                      confirmButtonColor: '#fd980f',
                      confirmButtonText: t('MyAccount.iMonthly.confirm'),
                      showCancelButton: true,
                      cancelButtonText: t('MyAccount.iMonthly.cancel'),
                      cancelButtonColor: '#888',
                      reverseButtons: true,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        if (showTempChangeTerminateButton) {
                          handleTempChangeTerminate(booking.id);
                          return;
                        }
                        handleTerminate(booking.id);
                      } else if (result.isDenied) {
                        Swal.close();
                      }
                    });
                  }}
                  label={t('MyAccount.iMonthly.TERMINATE')}
                />
              )}
            {/* {renewAvailable && changeService && (
              <StyledLink
                type="button"
                color="white"
                onClick={changeService}
                label={t('MyAccount.iMonthly.CHANGE')}
              />
            )} */}
          </ButtonRow>
        </DetailInner>
      </BookingItemView>
    </ItemContainer>
  );
}
