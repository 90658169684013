import styled from 'styled-components';
import imgback from '../../media/images/news_bg.png';

const Container = styled.div`
  height: 100%;
  background-color: #fff;
  .slick-prev {
    left: 0px;
  }

  .slick-next {
    right: 0px;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url(${imgback});
  background-size: cover;
  background-position: center;
  align-items: center;
  background-repeat: no-repeat;
`;

const PageTitle = styled.div`
  color: #fff;
  font-size: 3rem;
  margin-top: 40px;
  margin-bottom: 10px;
  display: flex;

  @media (max-width: 850px) {
    font-size: 1.875rem;
  }
`;

const UpperContainer = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 1000px;
  width: 100%;
  text-align: center;
  align-items: center;

  .slick-slider {
    max-width: 1000px;
    width: 100%;
  }
  .slick-list {
    margin: 0px 20px !important;
  }

  @media (max-width: 850px) {
    background: rgba(0, 0, 0, 0.7);
  }
`;

const ArrowDiv = styled.div`
  display: flex;
  width: 100%;
  margin-top: -50px;
  z-index: 1;
`;

const LeftArrow = styled.div`
  border-top-width: 0px;
  height: 0px;
  border-left-width: 0px;
  border-right-width: 60px;
  border-bottom-width: 50px;
  border-style: solid;
  background: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  color: #fd980f;
  width: 50%;
  left: 0px;

  @media (max-width: 850px) {
    width: 50%;
  }
`;

const RightArrow = styled.div`
  border-top-width: 0px;
  height: 0px;
  border-left-width: 60px;
  border-right-width: 0px;
  border-bottom-width: 50px;
  border-style: solid;
  background: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  color: #fd980f;
  width: 50%;
  right: 0px;
`;

const BottomContanier = styled.div`
  background: #fd980f;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  padding-top: 10px;
  flex-direction: row;
  position: relative;
  padding-bottom: 40px;
`;

export {
  Container,
  MainContainer,
  PageTitle,
  UpperContainer,
  ArrowDiv,
  LeftArrow,
  RightArrow,
  BottomContanier,
};
