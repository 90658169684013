import React, { useEffect } from 'react';
import '../Footer.css';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import styled, { keyframes } from 'styled-components';
import useMeasure from 'react-use-measure';
import ScreenWidthListener from '../../General/ScreenWidthListener';

const promotionicon = `${process.env.REACT_APP_CP_MEDIA_DOMAIN}/img/icon-promotion_promotion.png`;
const icontop = `${process.env.REACT_APP_CP_MEDIA_DOMAIN}/img/icon-top.png`;

const Imgdiv = styled.div`
  position: absolute;
  left: 10px;
  top: -23px;
`;

const PromoImg = styled.img`
  width: 45px;
  height: 60px;
`;

const Button = styled.button`
  position: absolute;
  background-color: #444;
  border-radius: 50px;
  padding: 5px;
  left: 89%;
  top: 7px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-color: transparent;

  &:focus {
    outline: none;
  }
`;

const ArrowImg = styled.img`
  width: 10px;
  height: 10px;
  transform: ${({ Menu }) => (Menu ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: all 0.1s ease-in-out;
`;

const Flicker = keyframes`
0%   { opacity:1; }
25%  { opacity:0; }
50%  { opacity:1; }
75%  { opacity:0; }
100% { opacity:1; }
`;

const TextDiv = styled.div`
  white-space: nowrap;
  margin-right: 40px;
  padding-left: 60px;
  margin-top: 8px;

  -webkit-animation: ${Flicker} 1.5s;
  -moz-animation: ${Flicker} 1.5s;
  -o-animation: ${Flicker} 1.5s;
  animation: ${Flicker} 1.5s;
`;

const ContentText = styled.span`
  white-space: break-spaces;
  margin-bottom: 0px;
`;

const DropDownMenu = styled.div`
  background: #ffe794;
  position: absolute;
  top: 40px;
  padding: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  color: #000;
  font-size: 13.2px;
  max-width: 360px;
  width: 100%;

  @media (max-width: 580px) {
    top: 40px;
    max-width: 100%;
  }
`;

const MenuTitle = styled.span`
  color: #444;
  text-transform: uppercase;
  font-size: 16px;
`;

const MenuTextDiv = styled.div`
  margin-top: 30px;
`;

const ClickText = styled.span`
  text-decoration-line: underline;
`;

const ClickA = styled.a`
  color: #000;
  &:hover {
    color: #000;
    text-decoration-line: none;
  }
`;

const Notification = styled.div``;

const TitleRow = styled.div`
  cursor: pointer;
`;

const ContentTitlte = styled.p`
  //font-weight: bolder;
  font-size: 13.2px;
  line-height: 1.5;
  white-space: break-spaces;
  margin: 0;
`;

function NotificationBar(props) {
  const { t, i18n } = useTranslation();
  const { outerWidth } = ScreenWidthListener();
  const [Menu, setMenu] = React.useState(true);
  const { shownotification = false, announcement = {} } = props;

  const [notiBarRef, { height: notiBarHeight }] = useMeasure();

  function checkOffset() {
    const FooterTop = $('#footer').offset().top;
    const notificationTop = $('#notificationContainer').offset().top;
    const notificationHeight = $('#notification').height();
    if (notificationTop + notificationHeight >= FooterTop - 10 && outerWidth > 580) {
      $('#notification').css({
        position: 'absolute',
        bottom: '',
        'margin-top': '-50px',
      });
    }

    if (notificationTop + notificationHeight >= FooterTop - 10 && outerWidth <= 580) {
      $('#notification').css({
        position: 'absolute',
        bottom: '',
        'margin-top': '-35px',
      });
    }

    if ($(document).scrollTop() + window.innerHeight < FooterTop) {
      $('#notification').css({
        position: 'fixed',
        bottom: '0px',
        'margin-top': '-50px',
      });
    }
  }

  $(document).scroll(() => {
    checkOffset();
  });

  useEffect(() => {
    checkOffset();
  }, [Menu]);

  const openMuen = () => {
    setMenu(!Menu);
    $('#first-child').slideToggle(1);
  };

  return (
    <div id="notificationContainer">
      {shownotification === true && (
        <Notification
          id="notification"
          style={{
            transform: Menu ? `translateY(-${notiBarHeight.toFixed(1)}px)` : 'translateY(0px)',
          }}
        >
          <TitleRow onClick={openMuen}>
            <Imgdiv>
              <PromoImg src={promotionicon} />
            </Imgdiv>
            <Button id="promobutton">
              <ArrowImg src={icontop} Menu={Menu} />
            </Button>
            <TextDiv>
              <MenuTitle>
                {i18n.language === 'en' ? announcement.title : announcement.chineseTitle}
              </MenuTitle>
            </TextDiv>
          </TitleRow>

          {Menu && (
            <ClickA
              href={i18n.language === 'en' ? announcement.urlLink : announcement.chineseUrlLink}
              target="_blank"
            >
              <DropDownMenu ref={notiBarRef}>
                <ContentTitlte
                  dangerouslySetInnerHTML={{
                    __html:
                      i18n.language === 'en' ? announcement.content : announcement.chineseContent,
                  }}
                />
                {/* <ContentText>{t('footer.promobarDetail1')}</ContentText> */}

                {/* <MenuTextDiv>
                  <ClickText>
                    <span style={{ textDecoration: 'underline' }}>
                      {` ${t('footer.promobarDetail2')} `}
                    </span>
                  </ClickText>
                  <ContentText style={{ textDecoration: 'underline' }}>
                    {t('footer.promobarDetail3')}
                  </ContentText>
                </MenuTextDiv> */}
              </DropDownMenu>
            </ClickA>
          )}
        </Notification>
      )}
    </div>
  );
}

export default NotificationBar;
